import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import Tweet from "../../components/Tweet";
import CaptionedImage from "../../components/CaptionedImage";
import FlowGrid from '../../components/FlowGrid';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`Update (9/16/2020): iOS 14 introduces issues related to keyboard safe area insets which basically make this approach invalid - it still works if your app is built with Xcode 11, but building with Xcode 12 will cause the cell layouts whose rects intersect with the keyboard rect to break. For more information see `}<a parentName="p" {...{
          "href": "https://twitter.com/noahsark769/status/1305999564990750720"
        }}>{`this tweet`}</a>{`, the `}<a parentName="p" {...{
          "href": "https://developer.apple.com/forums/thread/658432"
        }}>{`developer forums post with no response`}</a>{`, and feedbacks FB8305409, FB8240609, and FB8176223. Unfortunately, based on this, I don't think I can recommend taking this approach for writing table cells anymore, but it's still a great exercise in understanding how SwiftUI and UIKit interop work. If anything changes about this, I'll try my best to update this post with new information.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Update (9/18/2020): The issues detailed in the above update `}<a parentName="p" {...{
          "href": "https://twitter.com/zntfdr/status/1306913858263552001?s=20"
        }}>{`seem to be resolved`}</a>{` as of Xcode 12.2 beta 1. I've created `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/UIHostingControllerAvoidanceExample"
        }}>{`UIHostingControllerAvoidanceExample`}</a>{`, which is a simpler reproduction case of the issues which existed before. However, as `}<a parentName="p" {...{
          "href": "https://twitter.com/NathanBLawrence"
        }}>{`Nathan Lawrence`}</a>{` details in `}<a parentName="p" {...{
          "href": "https://twitter.com/NathanBLawrence/status/1307091446739107848"
        }}>{`this great Twitter thread`}</a>{`, there are other reasons to be careful about taking this approach in production apps. I posted a `}<a parentName="p" {...{
          "href": "https://twitter.com/noahsark769/status/1307094916724600838?s=20"
        }}>{`Tweet`}</a>{` with more of my thoughts on it, but if you try to embed SwiftUI content inside your table view cells, be prepared to hack around issues - they'll surely continue to crop up as SwiftUI matures.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Update (10/15/2020): Just wanted to note so this post has up to date information - the issue still happens in the Xcode 12.1 GM build. Looks like we'll have to wait until Xcode 12.2.`}</p>
    </blockquote>
    <CaptionedImage filename="swiftuicell-1.png" max={300} caption="If you want to build these cells in SwiftUI, this is the post for you" alt="Screenshot of SwiftUI table view cells displayed in the sample app." mdxType="CaptionedImage" />
    <p>{`It's been a year since SwiftUI was released, and many iOS developers who are lucky to only support the two most current iOS versions will soon be able to start writing SwiftUI in their production apps. There are clear benefits to using SwiftUI (I could probably devote a whole post to them), but the question I've been thinking about lately is: where to start?`}</p>
    <p>{`We won't be rewriting everything in SwiftUI, at least not yet. But are there places in our apps where we can try it out, familiarize our team with it, and set ourselves up for more investment in SwiftUI going forward?`}</p>
    <p>{`One area that keeps popping into my head for this is `}<inlineCode parentName="p">{`UITableView`}</inlineCode>{` cells. At work, most of our app is composed of table views managed by `}<a parentName="p" {...{
        "href": "https://github.com/plangrid/ReactiveLists"
      }}>{`ReactiveLists`}</a>{`, usually displaying lists of data models or details about a model. Lots of times we use `}<inlineCode parentName="p">{`UITableViewCell`}</inlineCode>{` out of the box, but occasionally the cells are more complicated - we need a tertiary label, or a certain highlight state, or a custom view in the cell. Our current practice is to use XIBs for these cells, but I've been wondering whether we could start writing them in SwiftUI instead.`}</p>
    <p>{`To test this out, I re-wrote the list cells from `}<a parentName="p" {...{
        "href": "https://apps.apple.com/us/app/cifilter-io/id1457458557"
      }}>{`CIFilter.io`}</a>{` as SwiftUI views inside of self-sizing `}<inlineCode parentName="p">{`UITableViewCell`}</inlineCode>{`s. In this post I'll talk about how to implement a self-sizing cell setup with SwiftUI, so you don't have to run into the same roadblocks I did.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Sample code for this article is available `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGSwiftUITableCellSizing"
        }}>{`here`}</a>{`. If you're just looking for a `}<inlineCode parentName="p">{`UITableViewCell`}</inlineCode>{` subclass which you can drop a SwiftUI view into, skip to the bottom of this post 👇`}</p>
    </blockquote>
    <h2 {...{
      "id": "self-sizing-cells"
    }}>{`Self Sizing Cells`}</h2>
    <p>{`Self Sizing `}<inlineCode parentName="p">{`UITableViewCell`}</inlineCode>{`s are a concept that pretty much every iOS developer I know has struggled with. There's `}<a parentName="p" {...{
        "href": "https://www.raywenderlich.com/8549-self-sizing-table-view-cells"
      }}>{`an`}</a>{` `}<a parentName="p" {...{
        "href": "https://pspdfkit.com/blog/2018/self-sizing-table-view-cells/"
      }}>{`absolutely`}</a>{` `}<a parentName="p" {...{
        "href": "https://medium.com/@vhart/self-sizing-tableview-cells-a165086d8631"
      }}>{`huge`}</a>{` `}<a parentName="p" {...{
        "href": "https://useyourloaf.com/blog/self-sizing-table-view-cells/"
      }}>{`amount`}</a>{` `}<a parentName="p" {...{
        "href": "https://useyourloaf.com/blog/self-sizing-table-view-cells-in-interface-builder/"
      }}>{`of`}</a>{` `}<a parentName="p" {...{
        "href": "https://fluffy.es/dynamic-height-tableview-cell/"
      }}>{`writing`}</a>{` online about what they are and how to configure them.`}</p>
    <p>{`Historically, you defined the height of a table view cell using the `}<inlineCode parentName="p">{`tableView(_:heightForRowAt:)`}</inlineCode>{` method of your table view's delegate. This works well for standard cells, but once we have cells which use dynamic data, custom views, or views whose height are dependent on their width (like labels), calculating the height up front got very complicated.`}</p>
    <p>{`So in iOS 5, the idea of a self-sizing table view cell was introduced. If you specify `}<inlineCode parentName="p">{`UITableView.automaticDimension`}</inlineCode>{` from `}<inlineCode parentName="p">{`tableView(_:heightForRowAt:)`}</inlineCode>{`, `}<inlineCode parentName="p">{`UITableView`}</inlineCode>{` will calculate the cell's height based on its autolayout constraints, so you don't have to define the height yourself. This is really useful for custom cell layouts and dynamic text adjustments, and Apple even has a `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/uifont/creating_self-sizing_table_view_cells"
      }}>{`sample project`}</a>{` demonstrating how to use self-sizing cells to adapt to dynamic type.`}</p>
    <p>{`However, it can be hard to get the setup right when working with self-sizing cells. I can't count the number of times I've struggled with implementing a custom cell, then later in the table view seeing it reused with the wrong height, and trying every combination of `}<inlineCode parentName="p">{`setNeedsLayout`}</inlineCode>{`, `}<inlineCode parentName="p">{`layoutIfNeeded`}</inlineCode>{`, `}<inlineCode parentName="p">{`setNeedsUpdateConstraints`}</inlineCode>{`, `}<inlineCode parentName="p">{`invalidateIntrinsicContentSize`}</inlineCode>{`, etc to no avail. I'm pretty sure `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/47949185/self-sizing-table-view-with-different-constraint"
      }}>{`the`}</a>{` `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/38230195/adjusting-tableview-height-while-using-self-sizing-cells-and-a-non-scrollable-ta"
      }}>{`number`}</a>{` `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/50780893/how-make-custom-uitableviewcell-with-dynamic-height"
      }}>{`of`}</a>{` `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/42416733/self-sizing-tableview-cells-based-on-two-subviews-height"
      }}>{`stackoverflow`}</a>{` `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/44359185/self-sizing-tableview"
      }}>{`questions`}</a>{` `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/35712724/self-sizing-cells-and-dynamic-size-controls-for-ios"
      }}>{`about`}</a>{` `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/31979932/ios8-self-sizing-static-tableview-cells-with-interface-builder/45168279"
      }}>{`this`}</a>{` is approaching uncountable. In this article we'll go over the correct incantation to make this setup work.`}</p>
    <h2 {...{
      "id": "goals"
    }}>{`Goals`}</h2>
    <p>{`The cells we're going to look at defining in SwiftUI will demonstrate how complicated dependent cell layouts can be. We'll start with the classic example: two labels, two different font sizes, each one wrapping onto the next line if it's long enough. We'll use a string with variable number of repetitions for the text, and throw in a `}<inlineCode parentName="p">{`Rectangle`}</inlineCode>{` to show the cell's full height, for good measure:`}</p>
    <CaptionedImage filename="swiftuicell-2.png" max={400} caption="Our double-label cell" alt="Screenshot of the cell with two labels, each wrapping onto the next line, stacked on top of each other" mdxType="CaptionedImage" />
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`CellView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` content: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` numberOfRepetitions: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` body: some View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`HStack`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`alignment`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`center`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`spacing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`10`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Rectangle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fill`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Color`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`opaqueSeparator`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`width`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`VStack`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`alignment`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`leading`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`spacing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`4`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`content`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`lineLimit`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`repeating`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: content + `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`" "`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`count`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: numberOfRepetitions`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                ))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`font`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`caption`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`foregroundColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Color`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`secondaryLabel`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`lineLimit`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`padding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`([.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`top`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bottom`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`], `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`10`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Spacer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`padding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`([.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`leading`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`trailing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`], `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`10`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Now let's consider a more complicated cell: this one has SwiftUI layouts, views which expand to fill available space, an embedded button, and let's just throw another multiline label in there for fun!`}</p>
    <CaptionedImage filename="swiftuicell-3.png" max={400} caption="Complicated cell" alt="Screenshot of the the complicated cell, with purple and yellow borders, blue, green, and red rectangles, and a button that says 'Tap Me!'" mdxType="CaptionedImage" />
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ComplicatedCellView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` value: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` body: some View {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        VStack {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            HStack {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                VStack {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Rectangle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`().`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fill`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`green`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                        .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`width`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`50`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`height`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`50`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Rectangle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`().`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fill`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`red`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                        .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`width`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`50`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`height`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`50`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Spacer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                }.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`minHeight`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`100`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Rectangle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fill`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`blue`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`minWidth`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`100`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                VStack {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`repeating`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Value "`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`count`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: value % `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`40`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    ) + `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`\\(`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`value % `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`40`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                        .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`lineLimit`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Rectangle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`().`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fill`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`red`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`width`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`50`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`height`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`50`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Spacer`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Button`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`action`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: {}, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`label`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Text`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Tap me!"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }).`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`padding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bottom`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`5`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`border`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`yellow`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`padding`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`minHeight`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`maxHeight`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`infinity`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`border`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(Color.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`purple`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Our goal will be to correctly embed these views in cells such that:`}</p>
    <ol>
      <li parentName="ol">{`The cell gets its height from the layout`}</li>
      <li parentName="ol">{`The cells get properly reused with the correct height`}</li>
      <li parentName="ol">{`The table view maintains performant scrolling`}</li>
    </ol>
    <h2 {...{
      "id": "sizethatfits"
    }}>{`sizeThatFits`}</h2>
    <p>{`Whenever I have to implement a custom self-sizing table view cell, I find myself returning to `}<a parentName="p" {...{
        "href": "https://pspdfkit.com/blog/2018/self-sizing-table-view-cells/#:~:text=Self%2Dsizing%20cells%20in%20UITableView,the%20size%20of%20each%20cell."
      }}>{`this excellent post`}</a>{` by `}<a parentName="p" {...{
        "href": "https://twitter.com/qdoug"
      }}>{`Douglas Hill`}</a>{`. One approach mentioned in that article is to just override `}<inlineCode parentName="p">{`sizeThatFits`}</inlineCode>{` and `}<inlineCode parentName="p">{`layoutSubviews`}</inlineCode>{` in the cell subclass - this is a brute force approach, but it's the first one I tried. Let's see what this looks like when we wrap a `}<inlineCode parentName="p">{`UIHostingController`}</inlineCode>{` that contains our SwiftUI view:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// Note: This isn't the best way to implement a hosting cell.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// See later in the post for a better method`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`final`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`HostingCell`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`Content`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`View`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>: UITableViewCell {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` hostingController = UIHostingController<Content?>(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`style`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UITableViewCell.CellStyle, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`reuseIdentifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`style`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: style, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`reuseIdentifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: reuseIdentifier)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`backgroundColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`clear`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`sizeThatFits`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`size`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: CGSize) -> CGSize {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` hostingController.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`sizeThatFits`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`in`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: size)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`layoutSubviews`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`layoutSubviews`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`frame`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`size`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`sizeThatFits`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(bounds.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`size`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`public`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: Content) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`hostingController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = rootView`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` !`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`subviews`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`contains`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addSubview`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`We're taking advantage of the fact that `}<inlineCode parentName="p">{`UIHostingController`}</inlineCode>{` defines a `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/swiftui/uihostingcontroller/sizethatfits(in:)"
      }}>{`sizeThatFits`}</a>{` method, and we're taking that exact size and using it to manually layout the cell's subviews.`}</p>
    <p>{`Our table view delegate method will dequeue the cell, generate a view with repetitions based on the index path, and set that view on the cell. For demo purposes, we'll put a complicated cell every 6th row:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`tableView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`tableView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UITableView,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`cellForRowAt`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`indexPath`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: IndexPath`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) -> UITableViewCell {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` indexPath.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`row`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` % `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`6`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` == `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` cell = tableView.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`dequeueReusableCell`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`withIdentifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"HostingCell<ComplicatedCellView>"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`for`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: indexPath`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        ) as! HostingCell<ComplicatedCellView>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        cell.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`ComplicatedCellView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`value`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: indexPath.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`row`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` cell`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    } `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` cell = tableView.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`dequeueReusableCell`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`withIdentifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"HostingCell<CellView>"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`for`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: indexPath`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        ) as! HostingCell<CellView>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        cell.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CellView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`content`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Title Title Title "`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`numberOfRepetitions`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: indexPath.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`row`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` % `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`20`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` + `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        ))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` cell`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Run the app and...`}</p>
    <CaptionedImage filename="swiftuicell-4.png" max={400} caption="Yikes" alt="Screenshot of the iOS simulator with the layouts not working - each complicated layout is rendered over the other cells, and the label text doesn't wrap" mdxType="CaptionedImage" />
    <p>{`Well, that's not at all what we were expecting - the complicated cell isn't getting the height set correctly at all, and none of the labels wrap.`}</p>
    <p>{`After a couple minutes of scratching my head about this, I realized that `}<inlineCode parentName="p">{`sizeThatFits`}</inlineCode>{` was returning 0 for the complicated cell - which actually makes sense, since its layout (`}<inlineCode parentName="p">{`.frame(minHeight: 0, maxHeight: .infinity)`}</inlineCode>{`) doesn't have a fixed height. To fix this, we can use the `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/swiftui/view/fixedsize(horizontal:vertical:)"
      }}>{`fixedSize`}</a>{` modifier to fix the view at its ideal size in the vertical direction. After adding `}<inlineCode parentName="p">{`.fixedSize(horizontal: false, vertical: true)`}</inlineCode>{` to both our views, we get something that looks like what we want!`}</p>
    <CaptionedImage filename="swiftuicell-5.png" max={400} caption="" alt="Screenshot of the iOS simulator in portrait orientation with the layouts working with their correct height" mdxType="CaptionedImage" />
    <p>{`There's one last issue though: like I said, using `}<inlineCode parentName="p">{`sizeThatFits`}</inlineCode>{` is a brute force method. It doesn't take into account any of the other things about the layout environment, like layout margins and safe area insets. In fact, if we turn the device sideways, we can see that the cell gets obscured by the notch:`}</p>
    <CaptionedImage filename="swiftuicell-6.png" max={500} caption="Double yikes" alt="Screenshot of the iOS simulator in landscape orientation with complicated cell layout partially obscured by the device's notch" mdxType="CaptionedImage" />
    <p>{`At this point, I ended up giving up on this approach. It's probably possible to take safe area into account here, but it's not extensible. As we'll see shortly, there's a better way!`}</p>
    <h2 {...{
      "id": "autolayout"
    }}>{`Autolayout`}</h2>
    <p>{`The actual recommended way to implement self-sizing cells is with autolayout, so let's try adapting our `}<inlineCode parentName="p">{`HostingCell`}</inlineCode>{` to use constraints:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`final`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`HostingCell`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`Content`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`View`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>: UITableViewCell {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` hostingController = UIHostingController<Content?>(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`style`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UITableViewCell.CellStyle, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`reuseIdentifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`style`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: style, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`reuseIdentifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: reuseIdentifier)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`backgroundColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`clear`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`public`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: Content) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`hostingController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = rootView`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` !`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`subviews`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`contains`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addSubview`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`translatesAutoresizingMaskIntoConstraints`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`false`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`leadingAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`leadingAnchor`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            ).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`trailingAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`trailingAnchor`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            ).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`topAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`topAnchor`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            ).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bottomAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bottomAnchor`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            ).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`It's classic autolayout: add the view to the cell's `}<inlineCode parentName="p">{`contentView`}</inlineCode>{`, set up constraints, and everything should work from there. The first page of cells gets the right size, but unfortunately there are some issues when the cells get reused:`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="swiftuicell-7.png" alt="Layout working correctly when the app first loads" caption="First load" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="swiftuicell-8.png" alt="Table view with incorrect cell heights after scrolling" caption="After scrolling" mdxType="CaptionedImage" />
    </FlowGrid>
    <p>{`If you look closely, you can actually see that each cell is reusing the height of a previous cell - the heights aren't updated on reuse. I played around with this for a while but wasn't able to figure out what was going on - then it hit me that in autolayout, the size of a view without other height/width constraints comes from its `}<inlineCode parentName="p">{`intrinsicContentSize`}</inlineCode>{`. Turns out that setting the `}<inlineCode parentName="p">{`rootView`}</inlineCode>{` on a `}<inlineCode parentName="p">{`UIHostingController`}</inlineCode>{` doesn't immediately invalidate its `}<inlineCode parentName="p">{`intrinsicContentSize`}</inlineCode>{`, so we have to make the following, magical edit which basically makes everything work:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: Content) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`hostingController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = rootView`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`hostingController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`invalidateIntrinsicContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Now we've got what we want, and safe area works correctly!`}</p>
    <CaptionedImage max={500} filename="swiftuicell-10.png" alt="Complicated SwiftUI layout working but not overlapping the notch" caption="" mdxType="CaptionedImage" />
    <p>{`(It's worth noting, also, that we don't even need the `}<inlineCode parentName="p">{`fixedSize`}</inlineCode>{` modifier anymore - autolayout seems to use the fixed size of the view as expected.)`}</p>
    <h2 {...{
      "id": "view-controller-containment"
    }}>{`View Controller Containment`}</h2>
    <p>{`When implementing this for `}<a parentName="p" {...{
        "href": "https://apps.apple.com/us/app/cifilter-io/id1457458557"
      }}>{`CIFilter.io`}</a>{`, I ran into one last issue: when scrolling through the list, occasionally, I'd run across a cell with an incorrect height. Usually it would just be one cell, and the height would be `}<em parentName="p">{`almost`}</em>{` correct, and it always corrected itself when scrolling the cell off the screen then scrolling it back on again.`}</p>
    <CaptionedImage max={400} filename="swiftuicell-9.png" alt="Our same table view, but with the middle cell just slightly taller than it should be" caption="Notice the 4th cell from the top - it's just slightly taller than it should be, as evidenced by the extra whitespace" mdxType="CaptionedImage" />
    <p>{`This was a real head scratcher, but I asked about it on Twitter and `}<a parentName="p" {...{
        "href": "https://steipete.me/"
      }}>{`Peter Steinberger`}</a>{` noticed something interesting:`}</p>
    <Tweet tweetId="1280165087635017728" mdxType="Tweet" />
    <p>{`The code we wrote before, even though it embeds the `}<inlineCode parentName="p">{`UIHostingController`}</inlineCode>{`'s view in the hierarchy, doesn't properly add the controller as a child controller as `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/uiviewcontroller/1621394-addchild"
      }}>{`recommended by the docs`}</a>{`. In order to account for this, we need to make our `}<inlineCode parentName="p">{`set(rootView:)`}</inlineCode>{` method take a parent controller as well, and do a little bit of extra work to add the child:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: Content, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`parentController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIViewController) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`hostingController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = rootView`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`hostingController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`invalidateIntrinsicContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` requiresControllerMove = hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`parent`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` != parentController`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` requiresControllerMove {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        parentController.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addChild`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(hostingController)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// addSubview, set up constraints, etc...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` requiresControllerMove {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        hostingController.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`didMove`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`toParent`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: parentController)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`And magically, this works! When using proper view controller containment, I haven't been able to detect any incorrect sizes at all - the cell layouts just work, there are no detectable scroll performance issues. For whatever reason, adding proper view controller containment solves the issue.`}</p>
    <h2 {...{
      "id": "caveats"
    }}>{`Caveats`}</h2>
    <p>{`This approach has worked really well for my use case, but you can't do everything SwiftUI can do inside these cell views and expect them to work. Specifically:`}</p>
    <ol>
      <li parentName="ol">{`Triggering something in your view which changes its height won't update the cell's height. Regular table view rules apply: if you want to change the size of the cell, you have to hook into `}<a parentName="li" {...{
          "href": "https://stackoverflow.com/questions/460014/can-you-animate-a-height-change-on-a-uitableviewcell-when-selected#:~:text=Store%20the%20cell%20height%20in,beginUpdates%5D%3B%20%5BtableView%20endUpdates%5D%3B"
        }}>{`hook into the table view to notify that it should update`}</a>{`.`}</li>
      <li parentName="ol">{`Updating `}<inlineCode parentName="li">{`@State`}</inlineCode>{` variables produces inconsistent behavior - `}<inlineCode parentName="li">{`@State`}</inlineCode>{` updates are persisted between cell reuse sometimes, even if your view initializer resets the `}<inlineCode parentName="li">{`@State`}</inlineCode>{` variable. There might be a way to get this to work, but I haven't been able to figure it out yet - if you want to update your cells, it's best to call `}<a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/uikit/uitableview/1614935-reloadrows"
        }}>{`reloadRows`}</a>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`At the end of the day, whether you want to take this approach to start adopting SwiftUI will be up to what you and your team are comfortable with. Hopefully this post has provided some insight into what it would take to start using SwiftUI in your table view cells so that you can make a more informed decision.`}</p>
    <p>{`If you have any thoughts, feel free to reach out `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`on Twitter`}</a>{` 👋`}</p>
    <h2 {...{
      "id": "code"
    }}>{`Code`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The full source code for the code samples in this article is available `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGSwiftUITableCellSizing"
        }}>{`here`}</a>{`.`}</p>
    </blockquote>
    <p>{`Here's the full `}<inlineCode parentName="p">{`HostingCell`}</inlineCode>{` code for reference:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`final`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`HostingCell`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`Content`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`View`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>: UITableViewCell {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` hostingController = UIHostingController<Content?>(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`style`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UITableViewCell.CellStyle, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`reuseIdentifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`style`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: style, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`reuseIdentifier`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: reuseIdentifier)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`backgroundColor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`clear`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`required`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init?`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`coder`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`aDecoder`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: NSCoder) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fatalError`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"init(coder:) has not been implemented"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`set`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: Content, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`parentController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIViewController) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`hostingController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`rootView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = rootView`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`hostingController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`invalidateIntrinsicContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` requiresControllerMove = hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`parent`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` != parentController`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` requiresControllerMove {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            parentController.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addChild`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(hostingController)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` !`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`subviews`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`contains`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addSubview`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`translatesAutoresizingMaskIntoConstraints`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`false`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`leadingAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`leadingAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`trailingAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`trailingAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`topAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`topAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bottomAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalTo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`contentView`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`bottomAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` requiresControllerMove {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            hostingController.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`didMove`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`toParent`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: parentController)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      